<template>
    <section class="main-content-section">
        <div class="grid">
            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="textpage">
                    <ul>
                        <li v-for="line in order.lines" :key="line.id" class="flex justify-space-between">
                            <span>{{line[`name${selectedLanguage}`]}}</span>
                            <span>{{line.quantity}}</span>
                        </li>
                    </ul>
                    <hr />

                    <div class="flex justify-space-between"><span>{{storeName}}</span><span>{{order.store.name}}</span></div>
                    <div class="flex justify-space-between"><span>{{address}}</span><span>{{order.store.address}}</span></div>
                    <div class="flex justify-space-between"><span>{{zipCode}}</span><span>{{order.store.zipCode}}</span></div>
                    <div class="flex justify-space-between"><span>{{city}}</span><span>{{order.store.city}}</span></div>

                    <hr />

                    <div class="flex justify-space-between"><span>{{name}}</span><span>{{order.user.name}}</span></div>
                    <div class="flex justify-space-between"><span>{{emailAddress}}</span><span>{{order.user.email}}</span></div>
                    <div class="flex justify-space-between"><span>{{phoneNumber}}</span><span>{{order.user.phone}}</span></div>
                    <div class="flex justify-space-between"><span>{{mobileNumber}}</span><span>{{order.user.mobile}}</span></div>
                    <div class="flex justify-center">
                        <button @click="print()" class="btn btn-primary alternative">{{printOrder}}</button>
                    </div>
                </div>
            </div>
        </div>    
    </section>        
</template>

<script>
import { mapGetters } from 'vuex';
import agent from '../../api/agent';
import { downloadDocument } from '../../utils/downloadService';

export default {
    name: 'ReservationConfirmation',
    created() {
        this.$store.dispatch('fetchOrder', this.$route.query.orderId);
    },
    computed: {
        ...mapGetters([
            'selectedLanguage', 
            'storeName', 
            'printOrder', 
            'address', 
            'zipCode', 
            'city', 
            'name',
            'emailAddress',
            'phoneNumber',
            'mobileNumber'
        ]),
        ...mapGetters({order: 'getOrder'})
    },
    methods: {
        async print() {
            try {
                const result = await agent.service.printOrder(this.$route.query.orderId);
                downloadDocument(result, 'pdf', 'reservation-confirmation');
            } catch (error) {
                console.log(error.response);
            }
        }
    }
}
</script>

<style>

</style>